import store from "@/store"

export default async (to, from, next) => {
  if (store.getters["content/GET_IS_CORE_CONTENT_READY"]) {
    next()
  } else {
    const unWatch = store.watch(
      () => store.getters["content/GET_IS_CORE_CONTENT_READY"],
      () => {
        if (store.getters["content/GET_IS_CORE_CONTENT_READY"]) {
          unWatch()
          next()
        }
      }
    )
  }
}
